import React from 'react'
import Navbar from '../../components/Navbar'

const BlogPage = () => {
   return (
     <div>
         <Navbar />
         <h1>Blog</h1>
     </div>
   )
 }

 export default BlogPage