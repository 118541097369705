import './App.css';
import { Route, Routes } from 'react-router-dom';
import HomePage from './pages/home/HomePage';
import BlogPage from './pages/blog/BlogPage';
import { ThemeProvider, createTheme } from '@mui/material/styles';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});

function App() {
  return (
    <ThemeProvider theme={darkTheme}>
      <div className="App">
        <Routes>
          <Route path='/' element={<HomePage/>} />
          <Route path='/blog' element={<BlogPage/>} />
        </Routes>
      </div>
    </ThemeProvider>
  );
}

export default App;
