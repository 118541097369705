import React from 'react'
import './home.css';

import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { Button, Typography, useMediaQuery } from '@mui/material';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import Chip from '@mui/material/Chip';

//Icons
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import EmailIcon from '@mui/icons-material/Email';

//Images 
import Profile from '../../assets/profile.png'
import P1 from '../../assets/p1.jpg'

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 8,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: '#25C07F',
  },
}));

// DATA ----------------------------------------------------------------
const contactInfo = [
	{
		info: <Typography>pablolz190402@gmail.com</Typography>, 
		icon: <MailOutlineIcon className='contact-icon'/>
	},
	/*{
		info: <Typography>+1 915 300 8551</Typography>, 
		icon: <LocalPhoneIcon className='contact-icon'/>,
	},*/
	{
		info: <a className='contact-anchor' href='https://www.linkedin.com/in/lopez-zubia-pablo-daniel-2763a029b/' target='blank'>Pablo Daniel Lopez Zubia</a>		, 
		icon: <LinkedInIcon className='contact-icon'/>
	},
]

const eduInfo = [
	{
		school: 'CBTis 114',
		career: 'Programming technician',
		finish: '2020',
		description: 'During this school period, I laid the foundations of programming through classes such as structured programming, object-oriented programming, databases, web pages, and mobile development.'
	},
	{
		school: 'Universidad Autonoma de Ciudad Juarez',
		career: 'Mechatronics Engineer',
		finish: 'Current',
		description: 'Throughout my career, I plan to combine my programming studies acquired through courses and work with hardware to create robots or mechatronic solutions for everyday problems.'
	}
]

const knowList = ['Graphics', 'UI/UX', 'Web Dev', 'Mobil Dev', 'Robotics', 'Frontend', 'Backend', 'VR', 'AR', 'MR',]

const skilsInfo = [
	{skill: 'Front-end', progress: 60},
	{skill: 'Back-end', progress: 40},
	{skill: 'Web Design', progress: 75},
	{skill: 'Movil Design', progress: 65}
]

const devInfo = [
	{skill: 'React (JS)', progress: 66},
	{skill: 'Angular (TS)', progress: 50},
	{skill: 'Flutter (Dart)', progress: 60},
	{skill: 'Kotlin', progress: 55},
	{skill: 'Swift', progress: 40},
	{skill: 'Python', progress: 33},
	{skill: 'JS', progress: 70},
	{skill: 'TS', progress: 50},
	{skill: 'Java', progress: 40},
]

const projectsInfo = [
	{name: 'VR Tour', image: {P1}, href:'https://pdlz.com.mx/vr-tour/'},
]

const HomePage = () => {
	const isMdScreen = useMediaQuery('(min-width:900px)');

	return (
		<div >
			<Grid container spacing={2} className='hero-container' >
				<Grid sm={12} md={6} className='left'>
					<Typography className='title1'> I am Pablo D. Lopez Z: </Typography>
					<Typography className='title2'> Developer </Typography>

					{contactInfo.map((contact, i) => (
						<Grid key={i} container className='contact-cont'>
							{contact.icon}
							<Typography>{contact.info}</Typography>
						</Grid>
					))}
					
					
				</Grid>

				<Grid sm={12} md={6} className='r'>
					<Grid className={!isMdScreen ? 'circle-big' : 'circle'}>
						<img src={Profile} width={'95%'} alt='profile-pic'/>
					</Grid>
				</Grid>
			</Grid>

			{/* About me ------------------------------- */}

			<Grid className='about-c'>
				
				<Typography className='t1'>About Me</Typography>
				<Typography className='t2'> 
				As a future mechatronics engineer, it would be a dream for my to create systems that implements IOT. Make smart components in the industries its important for a better quality life for us as humans, and this would be possible by adding multiple sensors that protect us and the environment of multiple situations that put in danger our life. 
				Also i have the idea to create an academy for young people in my city that focuses in the different ways of learning for them to get to know each other, make contacts around their environment and make projects with all the participants points of view. This would create a scientific and development community in the city that also would be open for all people.
				<br/><br/>
				For me, the key to make the world a better place is to help people to develop their potential and talents. All human beings have a talent that they don't fully explore because they didn't have someone to guide them in that path. Being able to understand and support others people talents its other way to show love and care to them, even if we don't them. 
				Helping a friend to get a job, going to their sports games, listen to the music they made would change a lot people thoughts about their life, and this is because they do what the love and also feeling support from other people. This is a easy, realistic way to change the world in to a better place with little actions.
				</Typography>

			</Grid>

			{ /* Skills ----------------------------------------------------------- */}
			<Grid container spacing={1} className='skills-c'>
				
				<Grid xs={12} className='t-c'>
					<Typography className='t1'>Experience & Skills</Typography>
					<Typography className='t2'> 
						A step-by-step overview of my professional and educational journey throughout my life.
					</Typography>
				</Grid>

				<Grid sm={12} md={6} className='e-c'>
					<Typography className='e-t'>Education</Typography>

					{eduInfo.map((data, i) => (
					<>
						<Typography className='school'> {data.school} </Typography>
						<Typography className='career'> {data.career} - 
							<Chip label={data.finish} onClick={() => {}} variant="outlined" color='success' className='chip'/>
						</Typography>
						<Typography className='des'> {data.description} </Typography>
					</>
					))}
					
					<Typography className='k-t'>Knowledge</Typography>

					<Grid className='k-c'>
						{knowList.map((data, i) => (
							<Box className="chip"> {data} </Box>
						))}
					</Grid>

				</Grid>

				<Grid sm={12} md={6}  className='s-c'>
					<Typography className='s-t' >Skills</Typography>

					{skilsInfo.map((data, i) => (
					<>
						<Typography className='t'> {data.skill} </Typography>
						<BorderLinearProgress variant="determinate" value={data.progress} />
					</>
					))}
					
					<Typography className='d-t'>Dev Skills</Typography>
					<Grid container spacing={2} className='d-c'>
						{devInfo.map((data, i) => (
							<>
								<Grid className='c'>
									<Typography className='t'> {data.skill} </Typography>
									<BorderLinearProgress variant="determinate" value={data.progress} />
								</Grid>
							</>
						))}
					</Grid>

				</Grid>
			</Grid>

			{/* Projects ------------------------------- */}

			<Grid className='p-t-c'>

				<Grid xs={12} className='t-c'>
					<Typography className='t1'> Projects</Typography>
					<Typography className='t2'> 
						A list of projects developed by me.
					</Typography>
				</Grid>
			</Grid>

			<Grid  container spacing={2} className='p-c'>

				{projectsInfo.map((data, i) => (
					<Grid xs={12} sm={12} md={6} lg={4} className='p-c'>
						<img src={P1} alt={'projectName'} />
						<div className="project-name">
							<a href='https://pdlz.com.mx/vr-tour/'>Virtual tour</a>
						</div>
					</Grid>
				))}

			</Grid>

			{ /* Contact */}
			<Grid container margin={0} px={'10%'} py={'64px'} spacing={2} color={'white'} sx={{backgroundColor: '#222E2A', width: '100%'}} justifyContent={'center'}>

				<Grid  xs={12}>
					<Typography fontSize={'38px'} fontWeight={'bold'}> Contact </Typography>
					<Typography paddingTop={'4px'}> 
						Quote a project, inquire about consulting, or request more information.
					</Typography>
				</Grid>

				{/*
				<Grid container spacing={2} s={12} md={8} display={'flex'} flexWrap={'wrap'} mt={'32px'}>
					<Grid item xs={6}>
						<TextField label="Name" id="outlined-size-small" size="small" color='success' fullWidth />
					</Grid>
					<Grid item xs={6}>
						<TextField label="Email" id="outlined-size-small" size="small" color='success' fullWidth />
					</Grid>
					<Grid item xs={12}>
						<TextField label="Subject" id="outlined-size-small" size="small" color='success' fullWidth />
					</Grid>
					<Grid item xs={12}>
						<TextField label="Message" id="outlined-size-small" size="small" color='success' fullWidth  multiline rows={6}/>
					</Grid>
					<Grid item xs={12}>
						<Button variant="contained" style={{width: '100%'}} color='success'>Send</Button>
					</Grid>
				</Grid>
			*/}

				<Grid s={12} md={12} display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'}>
					{contactInfo.map((contact, i) => (
						<Grid key={i} container className='contact-cont'>
							{contact.icon}
							<Typography>{contact.info}</Typography>
						</Grid>
					))}
					<Button variant="contained" color='success' startIcon={<EmailIcon />} onClick={() => window.location = 'mailto:pablolz190402@gmail.com'}>
					Enviar correo	
					</Button>
				</Grid>

			</Grid>

     </div>
   )
 }

 export default HomePage
